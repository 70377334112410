<template>
  <div class="max-w-2xl m-auto">
    <h1 class="text-3xl font-bold">PPP price calculator</h1>
    <subtitle class="opacity-80">for Play Store apps</subtitle>

    <p class="pt-4">This tool will generate a pricing structure adjusted for purchasing power parity, ready to import
      into Google Play. In other words, if you price your app at USD$5.99 in the USA this will allow users in Ecuador
      (for example) to purchase it as a lower price of $2.99 to account for the lower average wages.</p>

    <div class="text-left bg-amber-50 p-4 m-4 rounded"><!---->
      <p class="font-bold">How to use:</p>
      <ol class="list-decimal pl-4">
        <li>To generate your CSV pricing file, go to Google Play Console -> Products -> In-app products and click
          'Export'
        </li>
        <li>Enter the desired USD price below.</li>
        <li>Copy and paste the generated pricing code to replace the pricing code in the CSV for the product you want to
          reprice
        </li>
        <li>Save the CSV, and go back to 'In-app products' and click 'Import'</li>
      </ol>
    </div>

<!--    <p class="pt-4 pb-2">Developer account region:</p>

    <select name="region" id="region" class="border rounded " >
      <option v-for="country in playCountryNames" :key="country['cca2']" :value="country['cca2']">{{ country['name']['common'] }}</option>
    </select>-->

    <p class="pt-4 pb-2">Enter a price in USD:</p>
    <input v-model="text" class="border rounded w-24 py-2 px-3 ">


    <p class="pt-4 pb-2">Pricing code:</p>
    <p class=" bg-gray-300 p-8 rounded-xl text-sm">{{ playStoreRates }}</p>

    <p class="text-sm pt-4 px-8">Note: some countries local currency is not currently supported by Google Play, and
      local prices are in USD. For these countries the USD price is kept as is.</p>

    <h1 class=" py-8 text-xl font-bold">Thanks for using the tool!</h1>
  </div>
</template>

<script>
// import {getCountry} from "country-from-iso2";
import {getCountry} from "country-from-iso2";

const getCountryISO3 = require("country-iso-2-to-3");

export default {
  name: 'App',
  components: {},
  data() {
    return {
      rates: {
        'ABW': 1.342337742,
        'AFE': 0,
        'AFG': 19.12317027,
        'AFW': 0,
        'AGO': 220.2687153,
        'ALB': 42.9692589,
        'AND': 0,
        'ARB': 0,
        'ARE': 1.995850006,
        'ARG': 43.13540885,
        'ARM': 160.8045837,
        'ASM': 0,
        'ATG': 2.028070497,
        'AUS': 1.438979,
        'AUT': 0.770831,
        'AZE': 0.577726651,
        'BDI': 710.1333207,
        'BEL': 0.742723,
        'BEN': 209.0421409,
        'BFA': 206.8056921,
        'BGD': 32.09938209,
        'BGR': 0.720480271,
        'BHR': 0.184081657,
        'BHS': 0.827943328,
        'BIH': 0.678900368,
        'BLR': 0.854353,
        'BLZ': 1.277331,
        'BMU': 1.301402099,
        'BOL': 2.613056096,
        'BRA': 2.526131337,
        'BRB': 2.299263663,
        'BRN': 0.639882817,
        'BTN': 19.97935895,
        'BWA': 4.627685869,
        'CAF': 277.9272676,
        'CAN': 1.253066,
        'CEB': 0,
        'CHE': 1.104516,
        'CHI': 0,
        'CHL': 430.349555,
        'CHN': 4.187341612,
        'CIV': 240.7506062,
        'CMR': 226.7123476,
        'COD': 953.4350975,
        'COG': 339.4279345,
        'COL': 1358.650605,
        'COM': 189.2959849,
        'CPV': 45.7035766,
        'CRI': 332.026908,
        'CSS': 0,
        'CUB': 0,
        'CUW': 1.394338338,
        'CYM': 0.981214982,
        'CYP': 0.611900662,
        'CZE': 12.919712,
        'DEU': 0.741488,
        'DJI': 100.8813567,
        'DMA': 1.70470668,
        'DNK': 6.593618,
        'DOM': 23.70427254,
        'DZA': 42.24489598,
        'EAP': 0,
        'EAR': 0,
        'EAS': 0,
        'ECA': 0,
        'ECS': 0,
        'ECU': 0.508942388,
        'EGY': 4.567359797,
        'EMU': 0,
        'ERI': 0,
        'ESP': 0.624463,
        'EST': 0.546689,
        'ETH': 14.16684078,
        'EUU': 0,
        'FCS': 0,
        'FIN': 0.829754,
        'FJI': 0.873234605,
        'FRA': 0.725323,
        'FRO': 0,
        'FSM': 0.980934124,
        'GAB': 285.0239681,
        'GBR': 0.692802,
        'GEO': 0.95551434,
        'GHA': 2.341905693,
        'GIB': 0,
        'GIN': 4014.577876,
        'GMB': 17.67537934,
        'GNB': 219.1495762,
        'GNQ': 258.8707059,
        'GRC': 0.547807,
        'GRD': 1.607111008,
        'GRL': 0,
        'GTM': 3.978790585,
        'GUM': 0,
        'GUY': 79.80197479,
        'HIC': 0,
        'HKG': 5.851251669,
        'HND': 10.87426133,
        'HPC': 0,
        'HRV': 3.273840034,
        'HTI': 47.07517764,
        'HUN': 154.840118,
        'IBD': 0,
        'IBT': 0,
        'IDA': 0,
        'IDB': 0,
        'IDN': 4758.700957,
        'IDX': 0,
        'IMN': 0,
        'IND': 23.13813763,
        'INX': 0,
        'IRL': 0.787487,
        'IRN': 29704.30973,
        'IRQ': 703.2569697,
        'ISL': 150.642103,
        'ISR': 3.799707,
        'ITA': 0.654354,
        'JAM': 69.32332198,
        'JOR': 0.285622988,
        'JPN': 100.41164,
        'KAZ': 149.5366414,
        'KEN': 43.79894046,
        'KGZ': 20.42860716,
        'KHM': 1392.458621,
        'KIR': 1.013345344,
        'KNA': 1.874649733,
        'KOR': 847.456839,
        'KWT': 0.160610173,
        'LAC': 0,
        'LAO': 2852.484364,
        'LBN': 2958.133679,
        'LBR': 0.433465959,
        'LBY': 1.189305291,
        'LCA': 1.839087489,
        'LCN': 0,
        'LDC': 0,
        'LIC': 0,
        'LIE': 0,
        'LKA': 53.70343606,
        'LMC': 0,
        'LMY': 0,
        'LSO': 6.427022106,
        'LTE': 0,
        'LTU': 0.464377,
        'LUX': 0.851323,
        'LVA': 0.50634,
        'MAC': 4.926989508,
        'MAF': 0,
        'MAR': 3.859582996,
        'MCO': 0,
        'MDA': 6.00933523,
        'MDG': 1205.855631,
        'MDV': 7.583922067,
        'MEA': 0,
        'MEX': 10.043314,
        'MHL': 0.997487662,
        'MIC': 0,
        'MKD': 19.54569117,
        'MLI': 207.9867201,
        'MLT': 0.589317369,
        'MMR': 407.0644522,
        'MNA': 0,
        'MNE': 0.347427786,
        'MNG': 1004.551509,
        'MNP': 0,
        'MOZ': 24.40725658,
        'MRT': 11.29900862,
        'MUS': 16.51947252,
        'MWI': 312.3112783,
        'MYS': 1.590755394,
        'NAC': 0,
        'NAM': 7.128104204,
        'NCL': 0,
        'NER': 251.8709615,
        'NGA': 152.5691737,
        'NIC': 11.61365974,
        'NLD': 0.769839,
        'NOR': 9.674744,
        'NPL': 33.82868766,
        'NRU': 1.08701455,
        'NZL': 1.486354,
        'OED': 0,
        'OMN': 0.178985614,
        'OSS': 0,
        'PAK': 41.92062292,
        'PAN': 0.458218839,
        'PER': 1.868976146,
        'PHL': 19.16688474,
        'PLW': 0.872856482,
        'PNG': 2.301929132,
        'POL': 1.837204,
        'PRE': 0,
        'PRI': 0.915421958,
        'PRK': 0,
        'PRT': 0.571596,
        'PRY': 2617.455151,
        'PSE': 0.591015083,
        'PSS': 0,
        'PST': 0,
        'PYF': 0,
        'QAT': 2.384957176,
        'ROU': 1.745962144,
        'RUS': 27.331899,
        'RWA': 330.5245304,
        'SAS': 0,
        'SAU': 1.784958405,
        'SDN': 70.68470515,
        'SEN': 236.3801422,
        'SGP': 0.839571672,
        'SLB': 7.065688221,
        'SLE': 3117.463546,
        'SLV': 0.455956637,
        'SMR': 0.668928345,
        'SOM': 8912.143772,
        'SRB': 42.73586417,
        'SSA': 0,
        'SSD': 0,
        'SSF': 0,
        'SST': 0,
        'STP': 11.49526219,
        'SUR': 5.713469595,
        'SVK': 0.540124,
        'SVN': 0.565944,
        'SWE': 8.708853,
        'SWZ': 6.347919524,
        'SXM': 0,
        'SYC': 7.546109378,
        'SYR': 0,
        'TCA': 1.028070446,
        'TCD': 242.8009702,
        'TEA': 0,
        'TEC': 0,
        'TGO': 231.1925768,
        'THA': 12.0410677,
        'TJK': 2.365723743,
        'TKM': 0,
        'TLA': 0,
        'TLS': 0.330005062,
        'TMN': 0,
        'TON': 1.596078097,
        'TSA': 0,
        'TSS': 0,
        'TTO': 3.834328883,
        'TUN': 0.945825208,
        'TUR': 2.781851,
        'TUV': 1.385951785,
        'TZA': 890.5810196,
        'UGA': 1309.505486,
        'UKR': 9.278925747,
        'UMC': 0,
        'URY': 30.10312044,
        'USA': 1,
        'UZB': 2475.949699,
        'VCT': 1.539847127,
        'VEN': 0,
        'VGB': 0,
        'VIR': 0,
        'VNM': 7405.190014,
        'VUT': 109.9136094,
        'WLD': 0,
        'WSM': 1.577215035,
        'XKX': 0.331433136,
        'YEM': 0,
        'ZAF': 7.168096662,
        'ZMB': 6.190461706,
        'ZWE': 79.90261293,
        'TWN': 13.34,
        'VAT': 0.654354
      },
      playCountriesInLocalCurrency: ['US', 'GB', 'AE', 'AT', 'AU', 'BD', 'BE', 'BG', 'BO', 'BR', 'CA', 'CH', 'CL', 'CO', 'CR', 'CY', 'CZ', 'DE', 'DK', 'DZ', 'EC', 'EE', 'EG', 'ES', 'FI', 'FM', 'FR', 'GH', 'GI', 'GR', 'HK', 'HR', 'HU', 'ID', 'IE', 'IL', 'IN', 'IQ', 'IT', 'JO', 'JP', 'KE', 'KR', 'KZ', 'LI', 'LK', 'LT', 'LU', 'LV', 'MA', 'MC', 'MM', 'MO', 'MX', 'MY', 'NG', 'NL', 'NO', 'NZ', 'PA', 'PE', 'PH', 'PK', 'PL', 'PT', 'PY', 'QA', 'RO', 'RS', 'RU', 'SA', 'SE', 'SG', 'SI', 'SK', 'SM', 'SV', 'TC', 'TH', 'TR', 'TW', 'TZ', 'UA', 'VA', 'VN', 'ZA', 'GE'],
      playCountriesInUSD: ['BH', 'BM', 'VG', 'KH', 'KY', 'KW', 'OM'],
      playCountries: ['US', 'GB', 'AE', 'AT', 'AU', 'BD', 'BE', 'BG', 'BH', 'BM', 'BO', 'BR', 'CA', 'CH', 'CL', 'CO', 'CR', 'CY', 'CZ', 'DE', 'DK', 'DZ', 'EC', 'EE', 'EG', 'ES', 'FI', 'FM', 'FR', 'GH', 'GI', 'GR', 'HK', 'HR', 'HU', 'ID', 'IE', 'IL', 'IN', 'IQ', 'IT', 'JO', 'JP', 'KE', 'KH', 'KR', 'KW', 'KY', 'KZ', 'LI', 'LK', 'LT', 'LU', 'LV', 'MA', 'MC', 'MM', 'MO', 'MX', 'MY', 'NG', 'NL', 'NO', 'NZ', 'OM', 'PA', 'PE', 'PH', 'PK', 'PL', 'PT', 'PY', 'QA', 'RO', 'RS', 'RU', 'SA', 'SE', 'SG', 'SI', 'SK', 'SM', 'SV', 'TC', 'TH', 'TR', 'TW', 'TZ', 'UA',  'VA', 'VG', 'VN', 'ZA'],
      text: '5.99'
    }
  },
  computed: {
    // a computed getter
    playStoreRates() {
      if (isNaN(this.text) || this.text == '')
        return "..."
      let output = []

      for (const country of this.playCountriesInLocalCurrency) {
        let price = (this.rates[getCountryISO3(country)] * this.text).toPrecision(2) * 1

        if (price < 10 && price > 0 && country !== 'IL')
          price -= 0.01
        if ((price % 1) < 0.2)
          price += 0.2

        // if (isNaN(price) || price <= 0.1)
        output.push(country + "; " + (price * 1000000).toPrecision(3) * 1)
      }

      for (const country of this.playCountriesInUSD) {
        // if (isNaN(price) || price <= 0.1)
        output.push(country + "; " + ((this.text * 1000000).toPrecision(3) * 1))
      }

      // `this` points to the component instance
      return output.join('; ')
    },
    playCountryNames(){
      return this.playCountries.map(iso2 => getCountry(iso2)).sort((a, b) => a['name']['common'] > b['name']['common'] ? 1 : -1);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
